<template>
    <div style="background: radial-gradient(120.74% 100.12% at 50% -20.19%, #03DAC6 0%, #3A269F 36.81%, #202020 100%);">
        <div>
            <headerComponent></headerComponent>
        </div>

        <div class="aboutNewGrid">

            <div class="aboutNewGridRow1">
                <div class="aboutNewGridRow1Column1">
                    <div class="aboutNewGridRow1Column1text">
                            <b>Monoverse</b> is owned by
                            <b>Monoverse Capital Ltd,</b> a
                            Private Company registered
                            and operated in London by a
                            team of senior executives,
                            <b>The Monoversians</b>.
                    </div>
                </div>
                <div class="aboutNewGridRow1Column2">
                    <img class="aboutNewGridRow1Column2Image" src="@/assets/images/aboutImage1.png" style="margin: auto">
                </div>

            </div>

            <div class="aboutNewGridRow2">
                <div class="aboutNewGridRow2Column1">
                    <img class="aboutNewGridRow2Column1Image" src="@/assets/images/aboutImage2.svg" style="margin: auto">
                </div>
                <div class="aboutNewGridRow1Column1">
                    <div class="aboutNewGridRow1Column1text">
                        <b>Monoversians</b> are trading
                        the world’s largest collection
                        of premium <b>.com</b> domains
                        ending with <b>Verse, Cakes &
                        Nomads</b>.
                    </div>
                </div>
            </div>



        </div>
        <div style="background-color: #141414">
            <div class="aboutNewGridRow3">
                <div class="aboutNewGridRow3Row1">
                    Monoverse is closed-ended, trademarked, branded, and regulated, towards the financial interests of its members.
                </div>

                <div class="aboutNewGridRow3Row2Column1">
                    <div class="aboutNewGridRow3Row2Column1Image" style="grid-column: 1">
                        <img class="aboutNewGridRow3Image" src="@/assets/images/about/aboutimage1.png" style="margin: auto">
                    </div>
                    <div class="aboutNewGridRow3Row2Column1ImageText" style="grid-column: 2">
                        <h4>Members’ seats are limited to the number of domains.</h4>
                    </div>

                </div>
                <div class="aboutNewGridRow3Row2Column2">
                    <div class="aboutNewGridRow3Row2Column1Image" style="grid-column: 1">
                        <img class="aboutNewGridRow3Image" src="@/assets/images/about/aboutimage2.png" style="margin: auto">
                    </div>
                    <div class="aboutNewGridRow3Row2Column1ImageText" style="grid-column: 2">
                        <h4>Branded with stunning NFT-like pixelated art, available to use for all Monoversians.</h4>
                    </div>
                </div>

                <div class="aboutNewGridRow3Row2Column1">
                    <div class="aboutNewGridRow3Row2Column1Image" style="grid-column: 1">
                        <img class="aboutNewGridRow3Image" src="@/assets/images/about/aboutimage3.png" style="margin: auto">
                    </div>
                    <div class="aboutNewGridRow3Row2Column1ImageText" style="grid-column: 2">
                        <h4>Branded with stunning NFT-like pixelated art, available to use for all Monoversians.</h4>
                    </div>
                </div>
                <div class="aboutNewGridRow3Row2Column2">
                    <div class="aboutNewGridRow3Row2Column1Image" style="grid-column: 1">
                        <img class="aboutNewGridRow3Image" src="@/assets/images/about/aboutimage4.png" style="margin: auto">
                    </div>
                    <div class="aboutNewGridRow3Row2Column1ImageText" style="grid-column: 2">
                        <h4>Branded with stunning NFT-like pixelated art, available to use for all Monoversians.</h4>
                    </div>
                </div>

            </div>

        </div>

        <div style="background-color: #202020">
            <div class="aboutNewGridRow4">
                <div class="aboutNewGridRow4Row1Column1">
                    <div class="aboutNewGridRow4Row1Column1Image">
                        <img class="aboutImage0809" src="@/assets/images/aboutImage9.svg" style="margin: auto">
                    </div>
                    <div class="aboutNewGridRow4Row1Column1Header">Building Blocks</div>
                    <div class="aboutNewGridRow4Row1Column1Text">
                        Monoverse Urban Landscape is designed to fit the core areas of personal and professional interests of Monoversians.
                    </div>
                    <div class="aboutNewGridRow4Row1Column1Btn">
                        <a href="/blocks"><img  src="@/assets/images/aboutImage10.svg" style="margin: auto"></a>
                    </div>
                </div>

                <div class="aboutNewGridRow4Row1Column2">
                    <div class="aboutNewGridRow4Row1Column1Image">
                        <img class="aboutImage0809" src="@/assets/images/aboutImage8.svg" style="margin: auto">
                    </div>
                    <div class="aboutNewGridRow4Row1Column2Header">Domain Portfolio</div>
                    <div class="aboutNewGridRow4Row1Column2Text">
                        Think of Monoverse Domains as an Investment to a Digital Assets that value will appreciate over time.
                    </div>
                    <div class="aboutNewGridRow4Row1Column1Btn">
                        <a href="/domains"><img  src="@/assets/images/aboutImage11.svg" style="margin: auto"></a>
                    </div>
                </div>

                <div class="aboutNewGridRow4Row2">
                    <p style="text-align: center">Manifesto</p>
                    <div>
                        Monoversians deem that “metaverse” is semantically wrong, and Monoverse is the only hypothetically valid iteration of the internet as a single universal and immersive virtual world.
                    </div>

                </div>
                <div class="aboutNewGridRow4Row3">
                    <img class="aboutNewGridRow4Row3Image" src="@/assets/images/aboutImage3.svg" style="margin: auto">
                </div>

            </div>
        </div>




<!--            <div class="aboutGrid1">-->
<!--                <div class="aboutGrid1Row" style="grid-row: 1" >-->
<!--                <div class="aboutGridColumn1R" >-->
<!--                    <div class="aboutGridColumn2Mobile" >-->
<!--                        <img class="aboutsvg1" src="@/assets/images/aboutImage1.svg" style="margin: auto">-->
<!--                    </div>-->
<!--                    <p class="aboutList">-->
<!--                        <b>Monoverse</b> is owned by-->
<!--                        <b>Monoverse Capital Ltd,</b> a-->
<!--                        Private Company registered-->
<!--                        and operated in London by a-->
<!--                        team of senior executives,-->
<!--                        <b>The Monoversians</b>.-->
<!--                    </p>-->
<!--                </div>-->
<!--                <div class="aboutGrid1Column2" >-->
<!--                    <img  src="@/assets/images/aboutImage1.svg" style="margin: auto">-->
<!--                </div>-->
<!--            </div>-->
<!--                <div class="aboutGrid1Row" style="grid-row: 2" >-->
<!--                <div class="aboutGrid1Column1L" >-->
<!--                    <img  src="@/assets/images/aboutImage2.svg" style="margin: auto">-->
<!--                </div>-->
<!--                <div class="aboutGridColumn2Text" >-->
<!--                    <div class="aboutGridColumn1LMobile" >-->
<!--                        <img class="aboutsvg1" src="@/assets/images/aboutImage2.svg" style="margin: auto">-->
<!--                    </div>-->
<!--                    <p class="aboutPtext">-->
<!--                        <b>Monoversians</b> are trading-->
<!--                        the world’s largest collection-->
<!--                        of premium <b>.com</b> domains-->
<!--                        ending with <b>Verse, Cakes &-->
<!--                        Nomads</b>.-->
<!--                    </p>-->
<!--                </div>-->
<!--            </div>-->

<!--        </div>-->

        <div>
            <mainfooter></mainfooter>
        </div>
    </div>
</template>

<script>
    import headerComponent from "@/components/reusable/header";
    import mainfooter from "@/components/footer";

    export default {
        name: "About",
        components: {headerComponent,mainfooter},
        data: () => ({
            show:false,
        }),
        mounted() {
            this.show = true; // might need this.$nextTick
        },

    }
</script>

<style>


</style>


